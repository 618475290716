import React from 'react'
import Reviews from './Reviews'
import Amenities from './Amenities'
import { useTranslation } from 'react-i18next';

const Accordion = ({ description, reviews, amenities }) => {
    const { t } = useTranslation();
    const cabin = t('cabin')
    return (
        <div className="accordion cabin-accordion" id="cabin-accordion">
            <div className="accordion-item cabin-accordion-item">
                <h2 className="accordion-header cabin-accordion-header">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-one" aria-expanded="true" aria-controls="collapse-one">
                        {cabin.description}
                    </button>
                </h2>
                <div id="collapse-one" className="accordion-collapse collapse show" data-bs-parent="#cabin-accordion">
                    <div className="accordion-body">
                        <p className='px-lg-5 pb-4'>
                            {description}
                        </p>
                    </div>
                </div>
            </div>
            <div className="accordion-item cabin-accordion-item">
                <h2 className="accordion-header cabin-accordion-header">
                    <button className="accordion-button accordion-border-top collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-two" aria-expanded="false" aria-controls="collapse-two">
                        {cabin.amenities}
                    </button>
                </h2>
                <div id="collapse-two" className="accordion-collapse collapse" data-bs-parent="#cabin-accordion">
                    <div className="accordion-body">
                        {
                            amenities.length > 0 ?
                                <Amenities data={amenities} /> :
                                <></>
                        }
                    </div>
                </div>
            </div>
            <div className="accordion-item cabin-accordion-item">
                <h2 className="accordion-header cabin-accordion-header">
                    <button className="accordion-button accordion-border-top collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-three" aria-expanded="false" aria-controls="collapse-three">
                        {cabin.reviews}
                    </button>
                </h2>
                <div id="collapse-three" className="accordion-collapse collapse" data-bs-parent="#cabin-accordion">
                    <div className="accordion-body">
                        {
                            reviews.length > 0 ?
                                <Reviews data={reviews} /> :
                                <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accordion