import Cabin from "../components/cabin/Cabin"
import { useSelector } from "react-redux"
import { selectHouses } from "../redux/slices/housesSlice"

const HousePitahaya = () => {
    const houses = useSelector(selectHouses)
    return (
        <>
            {
                houses.length > 0 ? <Cabin data={houses[1]} /> : <div>loading...</div>
            }
        </>
    )
}

export default HousePitahaya