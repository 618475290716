import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getHousesImages } from "../../api/api";

const initialState = {
    housesImages: [],
    status: "idle"
};

export const fetchHousesImages = createAsyncThunk(
    "housesImages/fetchHousesImages",
    async (id) => {
        const { data } = await getHousesImages(id);
        return data.items;
    }
);

export const housesImagesSlice = createSlice({
    name: "housesImages",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchHousesImages.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchHousesImages.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.housesImages = action.payload;
            })
            .addCase(fetchHousesImages.rejected, (state) => {
                state.status = "failed";
            });
    }
});

export const selectHousesImages = (state) => state.housesImages.housesImages;

export default housesImagesSlice.reducer;