import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getHeroImages } from "../../api/api";

const initialState = {
    heroImages: [],
    status: "idle"
};

export const fetchHeroImages = createAsyncThunk(
    "heroImages/fetchHeroImages",
    async () => {
        const { data } = await getHeroImages();
        return data.items;
    }
);

export const heroImagesSlice = createSlice({
    name: "heroImages",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchHeroImages.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchHeroImages.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.heroImages = action.payload;
            })
            .addCase(fetchHeroImages.rejected, (state) => {
                state.status = "failed";
            });
    }
});

export const selectHeroImages = (state) => state.heroImages.heroImages;

export default heroImagesSlice.reducer;



