import About from '../components/about/About'
import Carousel from '../components/about/Carousel'
import Hero from '../components/about/Hero'
import OurStory from '../components/about/OurStory'
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
    const { t } = useTranslation();
    const about = t('about');
    return (
        <>
            <Hero {...about} />
            <OurStory {...about} />
            <Carousel />
            <About {...about} />
        </ >
    )
}

export default AboutUs