import { useEffect } from "react";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux"
import { selectDarkMode } from "../redux/slices/darkModeSlice"
import { selectForm } from "../redux/slices/formSlice"
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Checkout = () => {
    let navigate = useNavigate();
    const darkMode = useSelector(selectDarkMode);
    const { datesAreAvailable, dates, totalPrice } = useSelector(selectForm);

    useEffect(() => {
        if (dates === null) {
            navigate("/");
        }
    }, [dates, navigate])

    return (
        <div>
            <div className="cabin-header bg-jobos-brown-dark position-relative text-center text-white">
                <h1 className="position-absolute top-65 start-50 translate-middle text-center text-white fs-40 fw-medium">Check Out</h1>
            </div>
            <div className={`${darkMode ? "text-white" : ""}`}>
                {
                    datesAreAvailable === null ? <></> :
                        datesAreAvailable ?
                            <Available dates={dates} price={totalPrice} /> :
                            <NotAvailable />
                }
            </div>
        </div>
    )
}

const NotAvailable = () => {
    const { t } = useTranslation();
    const { title, subtitle, button } = t("reservationnotcompleted");
    return (
        <div className="d-flex flex-column align-items-center text-center w-100 my-5 px-2">
            <i className="fa-solid fa-triangle-exclamation text-center text-jobos-brown" style={{ fontSize: "130px" }}></i>
            <h1 className="fw-bold text-jobos-brown-dark m-4 pt-3">{title}</h1>
            <p className="text-jobos-brown my-3 fw-bold fs-20">{subtitle}</p>
            <Link to={"/"} type="button" className="text-decoration-none jobos-btn text-center py-2 px-4 mt-5">{button}</Link>
        </div>
    )
}

const Available = ({ dates, price }) => {
    const { t } = useTranslation();
    const { title, subtitle, date, checkin, description, button } = t("reservationcompleted");
    return (
        <div className="d-flex flex-column align-items-center text-center w-100 my-5 px-2">
            <img src="/images/shield-icon.png" alt="shield" />
            <h1 className="fw-bold text-jobos-brown-dark m-4 pt-3">{title}</h1>
            <p className="text-jobos-brown my-3 fw-bold fs-20">{subtitle}</p>
            <i className="fa-regular fa-clock text-jobos-brown fs-45 pt-2"></i>
            <div className="fs-16 fw-medium text-jobos-gray-light my-4">
                <p className="m-0">{date}: {dates && dates[0]}</p>
                <p className="m-0">{checkin}: 03:00 pm</p>
            </div>
            <p className="fs-16 fw-medium text-jobos-gray-light lh-34 mb-5" style={{ maxWidth: "802px" }}>{description}</p>
            <p className="lh-53 fs-36 fw-bold text-jobos-brown-dark">Total: $ {price} USD</p>
            <Link to={"/"} type="button" className="text-decoration-none jobos-btn text-center py-2 px-4 mt-5">{button}</Link>
        </div>
    )
}

export default Checkout