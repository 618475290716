import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    darkMode: false,
};

export const darkModeSlice = createSlice({
    name: 'darkMode',
    initialState,
    reducers: {
        toggleDarkMode: (state) => {
            state.darkMode = !state.darkMode;
        }
    }
});

export const { toggleDarkMode } = darkModeSlice.actions;

export const selectDarkMode = state => state.darkMode.darkMode;

export default darkModeSlice.reducer;
