
const Hero = ({ hero }) => {
    const { title, subtitle } = hero;
    return (
        <div className='hero-about text-white'>
            <img className="hero-about-img" src="/images/hero/hero-about.png" alt="about us" />
            <div className="position-absolute w-100 px-4" style={{ top: "50%", zIndex: "100" }}>
                <h1 className="fs-50">{title}</h1>
                <div className="d-flex justify-content-center">
                    <p className="fs-20 fw-medium mb-2 ls-2 lh-34 hero-about-text">{subtitle}</p>
                </div>
            </div>
        </div>
    )
}

export default Hero