import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getHousesDates } from "../../api/api";

const initialState = {
    housesDates: [],
    status: "idle"
};

export const fetchHousesDates = createAsyncThunk(
    "housesDates/fetchHousesDates",
    async (id) => {
        const { data } = await getHousesDates(id);
        return data.items;
    }
);

export const housesDatesSlice = createSlice({
    name: "housesDates",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchHousesDates.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchHousesDates.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.housesDates = action.payload;
            })
            .addCase(fetchHousesDates.rejected, (state) => {
                state.status = "failed";
            });
    }
});

export const selectHousesDates = (state) => state.housesDates.housesDates;

export default housesDatesSlice.reducer;
