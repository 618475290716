import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    language: 'en',
};

export const translationSlice = createSlice({
    name: 'translation',
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload;
        }
    }
});

export const { setLanguage } = translationSlice.actions;

export const selectLanguage = state => state.translation.language;

export default translationSlice.reducer;