import { useSelector } from "react-redux";
import { selectForm } from "../../redux/slices/formSlice";
import useDates from "../../hooks/useDates";
import { useTranslation } from "react-i18next";

export const ErrorModal = () => {

    const { t } = useTranslation();
    const { first, second, third } = t("datepicker.error")

    const { houseId, dates } = useSelector(selectForm);
    const { parseStringToDate, countDaysBetweenDates } = useDates();
    let amountOfDays = 0;

    if (dates) {
        const startdate = parseStringToDate(dates[0]);
        const enddate = parseStringToDate(dates[1]);
        amountOfDays = countDaysBetweenDates(startdate, enddate);
        console.log("Amount of days: ", amountOfDays)
    }

    return (
        <div className="modal fade" id="errorModal" tabIndex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className='fs-30 fw-bold text-danger'>
                            <div className='mb-4 text-center'>
                                <i className="fa-solid fa-triangle-exclamation fs-45"></i>
                            </div>
                            {
                                !houseId && <p>{first}</p>
                            }
                            {
                                !dates && <p>{second}</p>
                            }
                            {
                                amountOfDays < 2 && <p>{third}</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ErrorModal