import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../../redux/slices/translationSlice';

const ChangeLanguage = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLanguage(i18n.language));
    }, [i18n.language, dispatch]);
    return (
        <button
            onClick={() => {
                i18n.changeLanguage(
                    i18n.language === 'en' ? 'es' : 'en'
                )
            }}
            className='btn btn-link text-white ms-lg-3 mt-4 mt-lg-0'
            type='button'
        >
            <img src={`/images/${t("flag") === "es" ? "es-flag.svg" : "usa-flag.svg"}`} alt="flag" />
        </button>
    )
}

export default ChangeLanguage