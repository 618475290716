import { useSelector } from "react-redux";
import { selectDarkMode } from "../../redux/slices/darkModeSlice"
import { Trans } from 'react-i18next';

const About = ({ about }) => {
    const darkMode = useSelector(selectDarkMode);
    const { cabins, wildlife, beaches } = about;

    return (
        <div className='py-5'>
            <div className="container py-xl-5">
                <div className="row">
                    <div className="col col-12 col-xl-4">
                        <div className="mb-3">
                            <img className="about-icon me-5" src="/images/houses/house-pitahaya-icon.png" alt="" />
                            <img className="about-icon" src="/images/houses/house-molenillo-icon.png" alt="" />
                        </div>
                        <div className="text-jobos-brown fs-45 lh-66">
                            <Trans i18nKey={"about.about.cabins.title"} />
                        </div>
                    </div>
                    <div className="col col-12 col-xl-8">
                        <p className={`fs-20 fw-normal lh-53 ls-2 m-0 ${darkMode ? "text-white" : ""}`}>{cabins.description}</p>
                    </div>
                </div>
            </div>
            <div className="position-relative py-5">
                <img className="wildlife-img" src="/images/about-us/colibri.png" alt="wildlife" />
                <div className="position-absolute top-50 start-50 translate-middle text-center text-white wildlife-text-container px-3">
                    <p className="fs-45 fw-bold ">{wildlife.title}</p>
                    <p className="fs-20 fw-normal lh-53 ls-2">{wildlife.description}</p>
                </div>
            </div>

            <div className="container py-xl-5">
                <div className="row justify-content-center">
                    <div className="col col-12 col-xl-5">
                        <p className="fs-20 fs-normal lh-30 text-jobos-green">{beaches.title}</p>
                        <p className="fs-42 fw-bold lh-63 text-jobos-brown me-3 me-lg-4">{beaches.subtitle}</p>
                        <p className={`fs-20 fw-normal lh-53 ls-2 ${darkMode ? "text-white" : ""}`}>{beaches.description}</p>
                    </div>
                    <div className="col col-12 col-xl-5">
                        <div className="d-none d-xl-flex justify-content-center align-items-center mt-4 mt-xl-0 h-100">
                            <img className="img-fluid" src="/images/about-us/beaches2.jpg" alt="beaches" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About