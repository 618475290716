import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getHouses, getHousesReviews, getHousesAmenities, getActivities, getHousesPrices } from "../../api/api";

const initialState = {
    houses: [],
    reviews: [],
    amenities: [],
    activities: [],
    prices: [],
    status: "idle"
};

export const fetchHouses = createAsyncThunk(
    "houses/fetchHouses",
    async () => {
        const { data } = await getHouses();
        return data.items;
    }
);

export const fetchHousesReviews = createAsyncThunk(
    "houses/fetchHousesReviews",
    async (id) => {
        const { data } = await getHousesReviews(id);
        return data.items;
    }
);

export const fetchHousesAmenities = createAsyncThunk(
    "houses/fetchHousesAmenities",
    async (id) => {
        const { data } = await getHousesAmenities(id);
        return data.items;
    }
);

export const fetchHousesPrices = createAsyncThunk(
    "houses/fetchHousesPrices",
    async (id) => {
        const { data } = await getHousesPrices(id);
        return data.items;
    }
);

export const fetchActivities = createAsyncThunk(
    "houses/fetchActivities",
    async () => {
        const { data } = await getActivities();
        return data.items;
    }
);

export const housesSlice = createSlice({
    name: "houses",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchHouses.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchHouses.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.houses = action.payload;
            })
            .addCase(fetchHouses.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(fetchHousesReviews.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchHousesReviews.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.reviews = action.payload;
            })
            .addCase(fetchHousesReviews.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(fetchHousesAmenities.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchHousesAmenities.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.amenities = action.payload;
            })
            .addCase(fetchHousesAmenities.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(fetchHousesPrices.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchHousesPrices.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.prices = action.payload;
            })
            .addCase(fetchHousesPrices.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(fetchActivities.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchActivities.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.activities = action.payload;
            })
            .addCase(fetchActivities.rejected, (state) => {
                state.status = "failed";
            });
    }
});

export const selectHouses = (state) => state.houses.houses;

export const selectReviews = (state) => state.houses.reviews;

export const selectAmenities = (state) => state.houses.amenities;

export const selectPrices = (state) => state.houses.prices;

export const selectActivities = (state) => state.houses.activities;

export default housesSlice.reducer;
