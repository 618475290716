import { useSelector } from "react-redux"
import { selectDarkMode } from "../../redux/slices/darkModeSlice"
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    const darkMode = useSelector(selectDarkMode);
    const { contact, shuttle } = t('footer');
    const number = "50663442700";
    const message = "Hola, me gustaría reservar un shuttle.";
    let url = `https://wa.me/${number}/?text=${encodeURI(message)}`;
    return (
        <div>
            <div className="container">
                <div className="py-5">
                    <div className="row">
                        <div className="col-12 col-xl-4">
                            <div className="d-flex pb-5 pb-xl-0">
                                <img className="mx-auto ms-xl-auto" src="/images/los-jobos-footer.png" alt="footer logo" style={{ maxWidth: "200px" }} />
                            </div>
                        </div>
                        <div className="col-12 col-xl-3">
                            <div className="d-flex flex-column align-items-start pt-5 pt-xl-0 px-4 px-xl-0">
                                <p className={`${darkMode ? "text-white" : ""}`}>{contact}</p>
                                <p><i className="fa-solid fa-phone text-jobos-green fs-19"></i> <a href="tel:0050663442700" className="footer-text" target="_blank" rel="noreferrer">+506 6344 2700</a></p>
                                <p><i className="fa-solid fa-envelope text-jobos-green fs-19"></i><a href="mailto:reservas@losjobostamarindo.com" className="footer-text" target="_blank" rel="noreferrer">reservas@losjobostamarindo.com</a></p>
                                <p><i className="fa-regular fa-rectangle-list text-jobos-green fs-19"></i><a href={`${url}`} className="footer-text" target="_blank" rel="noreferrer">{shuttle}</a></p>
                                <div className='d-flex'>
                                    <a href="https://www.facebook.com/profile.php?id=100075976217103&mibextid=LQQJ4d" target="_blank" rel="noreferrer" className="text-decoration-none me-3">
                                        <i className="fa-brands fa-facebook-square fa-2x text-jobos-green"></i>
                                    </a>
                                    <a href="https://www.instagram.com/cabanaslosjobos/" target="_blank" rel="noreferrer" className="text-decoration-none">
                                        <i className="fa-brands fa-instagram fa-2x text-jobos-green"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-5">
                            <div className="d-flex justify-content-center justify-content-xl-start pt-4 pt-xl-0">
                                <iframe
                                    className="footer-map"
                                    title="los-jobos-map"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1329.0614213159145!2d-85.82372941222387!3d10.294970948645895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f9e39fbf3b13df9%3A0x50ca0d54372eccec!2sLos%20jobos%20caba%C3%B1as!5e0!3m2!1ses-419!2scr!4v1680725803341!5m2!1ses-419!2scr"
                                    width="400"
                                    height="225"
                                    style={{ border: "0" }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-jobos-brown-dark d-flex align-items-center text-white" style={{ height: "93px" }}>
                <span className="mx-auto px-4 text-center">Derechos Reservados © 2023 Los Jobos Tamarindo Costa Rica</span>
            </div>
        </div>
    )
}

export default Footer