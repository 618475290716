import { useSelector } from 'react-redux';
import { selectHeroImages } from "../../redux/slices/herosImagesSlice";
import DatePicker from "../calendar/DatePicker"

const Hero = ({ hero }) => {
    const { title, subtitle } = hero;

    const heroImages = useSelector(selectHeroImages);
    return (
        <div id="carouselHero" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner position-relative">
                {
                    heroImages.length > 0 ?
                        heroImages.map((image, index) => {
                            return (
                                <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                    <div className='box-shadow'>
                                        <img src={image.url} className="hero-image" alt={image.name} />
                                    </div>
                                </div>
                            )
                        }) :
                        <div className='hero-image bg-dark'></div>
                }
                <div className="position-absolute text-white hero-text-container">
                    <h1 className="hero-title">{title}</h1>
                    <p className="hero-subtitle fs-20">{subtitle}</p>
                </div>
                <div className="position-absolute d-xxl-flex d-none hero-control-container">
                    <button className="circle-btn bg-jobos-green me-3" type="button" data-bs-target="#carouselHero" data-bs-slide="prev">
                        <i className="fa-solid fa-chevron-left text-white fs-6"></i>
                    </button>
                    <button className="circle-btn bg-jobos-green" type="button" data-bs-target="#carouselHero" data-bs-slide="next">
                        <i className="fa-solid fa-chevron-right text-white fs-6"></i>
                    </button>
                </div>
                <div className="position-absolute d-xxl-block d-none" style={{ top: "20%", right: "100px" }}>
                    <DatePicker />
                </div>
            </div>
        </div>
    )
}

export default Hero