import aboutCarousel from "../../constants/aboutCarousel";
import useChunks from "../../hooks/useChunks"

const Carousel = () => {

    const { groupByChunks } = useChunks();

    const chunks = groupByChunks(aboutCarousel, 4);

    return (
        <div className='py-xl-5'>
            <div className=''>
                <div id="caruselAbout" className="carousel slide d-none d-xl-block" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {
                            chunks.map((chunk, index) => {
                                return (
                                    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                        <div className='d-flex justify-content-between'>
                                            {
                                                chunk.map((image, index) => {
                                                    return (
                                                        <img className="carousel-img" key={index} src={`/images/about-us/${image}`} alt="" />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="d-flex justify-content-center justify-content-xl-end mt-4 me-xl-3">
                        <button className="circle-btn bg-jobos-green me-3" type="button" data-bs-target="#caruselAbout" data-bs-slide="prev">
                            <i className="fa-solid fa-chevron-left text-white fs-6"></i>
                        </button>
                        <button className="circle-btn bg-jobos-green" type="button" data-bs-target="#caruselAbout" data-bs-slide="next">
                            <i className="fa-solid fa-chevron-right text-white fs-6"></i>
                        </button>
                    </div>
                </div>

                <div id="caruselAboutMobile" className="carousel slide d-xl-none" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {
                            aboutCarousel.map((image, index) => {
                                return (
                                    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                        <div className='d-flex justify-content-center'>
                                            {
                                                <img className="carousel-img" key={index} src={`/images/about-us/${image}`} alt="" />
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="d-flex justify-content-center justify-content-xl-end mt-4 me-xl-3">
                        <button className="circle-btn bg-jobos-green me-3" type="button" data-bs-target="#caruselAboutMobile" data-bs-slide="prev">
                            <i className="fa-solid fa-chevron-left text-white fs-6"></i>
                        </button>
                        <button className="circle-btn bg-jobos-green" type="button" data-bs-target="#caruselAboutMobile" data-bs-slide="next">
                            <i className="fa-solid fa-chevron-right text-white fs-6"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Carousel