import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectHouses } from "../../redux/slices/housesSlice";
import { selectLanguage } from "../../redux/slices/translationSlice"

const Homes = ({ cabins }) => {

    const houses = useSelector(selectHouses);
    const language = useSelector(selectLanguage)
    const { shuttle, title1, title2, button } = cabins;

    const number = "50663442700";
    const message = "Hola, me gustaría reservar un shuttle.";
    let url = `https://wa.me/${number}/?text=${encodeURI(message)}`;
    return (
        <div className='py-5'>
            <div className='container text-jobos-brown presentation-spacer'>
                <p className='fs-45 m-0 text-center'>{title1}</p>
                <p className='fs-45 fw-bold text-center'>{title2}</p>
                <br />
                <br />
                <div className='row'>
                    {
                        houses.length > 0 ?
                            <>
                                <div className="col col-12 col-xxl-6">
                                    <div className='d-flex justify-content-center mb-5 mb-xxl-0'>
                                        <div className='position-relative houses-size'>
                                            <div className="position-absolute top-0">
                                                <div className="box-shadow-houses">
                                                    <img className='houses-image houses-size' src="/images/houses/house-molenillo.png" alt={houses[0].name} />
                                                </div>
                                                <div className='position-absolute text-white houses-text' style={{ bottom: "30px", left: "30px", width: "60%" }}>
                                                    <p className='fs-45 m-0'>{houses[0].name}</p>
                                                    <span className='fs-16 text-uppercase lh-30 ls-2'>{language === 'en' ? houses[0].description : houses[0].descriptionesp}</span>
                                                </div>

                                                <div className='position-absolute text-white text-end pb-3' style={{ bottom: "30px", right: "30px", width: "25%" }}>
                                                    <span className="text-decoration-underline fs-18">{button}</span> <i className="fa-solid fa-angle-right fs-12"></i>
                                                </div>
                                            </div>
                                            <div className="position-absolute w-100 top-0">
                                                <Link to="/molenillo" className="houses-hover houses-size d-flex flex-column justify-content-center align-items-center text-decoration-none p-2">
                                                    <img className="mb-4" src="/images/houses/house-molenillo-icon.png" alt="house molenillo icon" />
                                                    <p className="text-center text-jobos-brown fs-40 fw-bold">{houses[0].name}</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-xxl-6">
                                    <div className='d-flex justify-content-center'>
                                        <div className='position-relative houses-size'>
                                            <div className="position-absolute top-0">
                                                <div className="box-shadow-houses">
                                                    <img className='houses-image houses-size' src="/images/houses/house-pitahaya.png" alt={houses[1].name} />
                                                </div>
                                                <div className='position-absolute text-white houses-text' style={{ bottom: "30px", left: "30px", width: "60%" }}>
                                                    <p className='fs-45 m-0'>{houses[1].name}</p>
                                                    <span className='fs-16 text-uppercase lh-30 ls-2'>{language === 'en' ? houses[1].description : houses[1].descriptionesp}</span>
                                                </div>

                                                <div className='position-absolute text-white text-end pb-3' style={{ bottom: "30px", right: "30px", width: "25%" }}>
                                                    <span className="text-decoration-underline fs-18">{button}</span> <i className="fa-solid fa-angle-right fs-12"></i>
                                                </div>
                                            </div>
                                            <div className="position-absolute w-100 top-0">
                                                <Link to="/pitahaya" className="houses-hover houses-size d-flex flex-column justify-content-center align-items-center text-decoration-none p-2">
                                                    <img className="mb-4" src="/images/houses/house-pitahaya-icon.png" alt="house pitahaya icon" />
                                                    <p className="text-center text-jobos-brown fs-40 fw-bold">{houses[1].name}</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> : <></>
                    }
                    <div className="col col-12">
                        <div className='d-flex justify-content-center pt-5'>
                            <div className='position-relative houses-shuttle-size'>
                                <div className="position-absolute top-0">
                                    <div className="box-shadow-houses">
                                        <img className='houses-shuttle-image houses-shuttle-size' src="/images/houses/shuttle.png" alt="shuttle service" />
                                    </div>

                                    <div className='position-absolute text-white text-end house-shuttle-text'>
                                        <p className='fs-45 m-0'>{shuttle.title}</p>
                                        <span className='fs-14 text-uppercase ls-2 house-shuttle-description'>{shuttle.description}</span>
                                        <p className="text-decoration-underline mb-0 mt-4">{shuttle.button}</p>
                                    </div>
                                </div>
                                <div className="position-absolute w-100 top-0">
                                    <a href={url} target='_blank' rel='noreferrer' className="houses-shuttle-hover houses-shuttle-size d-flex flex-column flex-lg-row justify-content-center align-items-center text-decoration-none p-2">
                                        <img className="mb-3 mb-lg-0 me-lg-4" src="/images/features/bus.png" alt="shuttle service" style={{ width: "63.75px", height: "auto" }} />
                                        <p className="text-center text-jobos-brown fs-40 fw-bold m-0">{shuttle.title}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Homes