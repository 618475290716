import emailjs from '@emailjs/browser';

const useSendEmail = () => {
    const sendEmail = (emailParams) => {
        emailjs.send("service_gmail", "new_reservation", emailParams, '2S4hhHv3lc_1oBLSJ')
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
            }, function (error) {
                console.log('FAILED...', error);
            });
    }
    return { sendEmail }
}

export default useSendEmail