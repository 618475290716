import { useEffect } from "react";
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectDarkMode } from "../../redux/slices/darkModeSlice"
import { selectHouses } from "../../redux/slices/housesSlice";
import { selectForm, submitForm, setDatesAreAvailable, } from "../../redux/slices/formSlice";
import { fetchHousesDates } from "../../redux/slices/housesDatesSlice";
import useDates from "../../hooks/useDates";
import useSendEmail from "../../hooks/useSendEmail";

const Form = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { checkIfDatesAreOverlapping, checkIfStartDateIsWithinRanges, checkIfEndDateIsWithinRanges, parseStringToDate, addDays } = useDates();
    const darkMode = useSelector(selectDarkMode);
    const { houseId, dates, totalPrice } = useSelector(selectForm);
    const houses = useSelector(selectHouses);
    const { sendEmail } = useSendEmail();

    const { t } = useTranslation();
    const { title, name, email, phone, button } = t("datepicker.form")

    function handleSubmit(e) {
        e.preventDefault();

        const form = e.target;
        const formData = new FormData(form);
        const data = Object.fromEntries(formData.entries());

        const startDate = parseStringToDate(dates[0]);
        const endDate = parseStringToDate(dates[1]);

        const reservation = {
            startdate: startDate,
            enddate: endDate,
            createdat: addDays(new Date(), 3),
            email: data.email,
            phone: data.phone,
            name: data.name,
            houseid: houseId,
            status: "Solicitada",
            price: totalPrice
        }
        console.log("Reservation: ", reservation);

        dispatch(fetchHousesDates(houseId)).then(data => {
            const housesDates = data.payload;
            const overlapping = checkIfDatesAreOverlapping(startDate, endDate, housesDates);
            const startDateIsWithinRanges = checkIfStartDateIsWithinRanges(startDate, housesDates);
            const endDateIsWithinRanges = checkIfEndDateIsWithinRanges(endDate, housesDates);

            // console.log("Dates have an overlapping: ", overlapping);
            // console.log("Start date is within ranges: ", startDateIsWithinRanges);
            // console.log("End date is within ranges: ", endDateIsWithinRanges);

            if (overlapping || startDateIsWithinRanges || endDateIsWithinRanges) {
                dispatch(setDatesAreAvailable(false));
                // alert("The dates are not available");
            } else {
                dispatch(setDatesAreAvailable(true));
                dispatch(submitForm(reservation));
                sendEmail({
                    name: reservation.name,
                    email: reservation.email,
                    phone: reservation.phone,
                    house: houses.find(house => house.id === reservation.houseid).name,
                    startdate: dates[0],
                    enddate: dates[1],
                    price: totalPrice
                });
                // alert("The dates are available");
            }
        });

        const btnModal = document.getElementById("formModalBtn");
        btnModal.click();
        navigate('/checkout');
    }

    useEffect(() => {
        (() => {
            // Fetch all the forms we want to apply custom Bootstrap validation styles to
            const forms = document.querySelectorAll('.needs-validation')

            // Loop over them and prevent submission
            Array.from(forms).forEach(form => {
                form.addEventListener('submit', event => {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                    }

                    form.classList.add('was-validated')
                }, false)
            })
        })()
    }, [])

    return (
        <div className="modal fade" id="formModal" tabIndex="-1" aria-labelledby="formModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-fullscreen-xl-down">
                <div className="modal-content bg-transparent border-0">
                    <div className="modal-body">
                        <div className="d-flex justify-content-center w-100">
                            <form onSubmit={handleSubmit} className={`form-container p-5 ${darkMode ? "dark-mode" : "bg-white"} needs-validation`} noValidate>
                                <p className={`fw-bold lh-30 ls-2 fs-20 ${darkMode ? "text-jobos-brown" : "text-jobos-brown-dark"} text-center`}>{title}</p>
                                <div className="pb-4">
                                    <label className="form-label">{name.label}</label>
                                    <input type="text" required name="name" className="form-input form-control" />
                                    <div className="invalid-feedback">
                                        {name.error}
                                    </div>
                                </div>
                                <div className="pb-4">
                                    <label className="form-label">{email.label}</label>
                                    <input type="email" required name="email" className="form-input form-control" />
                                    <div className="invalid-feedback">
                                        {email.error}
                                    </div>
                                </div>
                                <div className="pb-4">
                                    <label className="form-label">{phone.label}</label>
                                    <input type="tel" required name="phone" className="form-input form-control" />
                                    <div className="invalid-feedback">
                                        {phone.error}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button type="submit" className='dp-btn mt-4 w-auto px-4' >
                                        {button}
                                    </button>
                                </div>
                                <button className="d-none" id="formModalBtn" data-bs-toggle="modal" data-bs-target="#formModal"></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Form