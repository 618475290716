import useChunks from "../../hooks/useChunks";
import { useTranslation } from 'react-i18next';

const Features = () => {

    const { t } = useTranslation();
    const { groupByChunks } = useChunks();
    const features = t('home.features');
    const chunks = groupByChunks(features, 4);

    return (
        <div className='container'>
            <div className='py-5'>
                <div id="carouselFeatures" className="carousel slide d-none d-xl-block" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {
                            chunks.map((chunk, index) => {
                                return (
                                    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} style={{ height: "200px" }}>
                                        <div className='d-flex justify-content-center'>
                                            {
                                                chunk.map((feature, index) => {
                                                    return (
                                                        <Content key={index} text={feature.text} image={feature.image} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="d-flex justify-content-center justify-content-xl-end">
                        <button className="circle-btn bg-jobos-green me-3" type="button" data-bs-target="#carouselFeatures" data-bs-slide="prev">
                            <i className="fa-solid fa-chevron-left text-white fs-6"></i>
                        </button>
                        <button className="circle-btn bg-jobos-green" type="button" data-bs-target="#carouselFeatures" data-bs-slide="next">
                            <i className="fa-solid fa-chevron-right text-white fs-6"></i>
                        </button>
                    </div>
                </div>
                <div id="carouselFeaturesMobile" className="carousel slide d-xl-none" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {
                            features.map((item, index) => {
                                return (
                                    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} style={{ height: "180px" }}>
                                        <div className='d-flex justify-content-center'>
                                            {
                                                <Content key={index} text={item.text} image={item.image} />
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="d-flex justify-content-center justify-content-xl-end">
                        <button className="circle-btn bg-jobos-green me-3" type="button" data-bs-target="#carouselFeaturesMobile" data-bs-slide="prev">
                            <i className="fa-solid fa-chevron-left text-white fs-6"></i>
                        </button>
                        <button className="circle-btn bg-jobos-green" type="button" data-bs-target="#carouselFeaturesMobile" data-bs-slide="next">
                            <i className="fa-solid fa-chevron-right text-white fs-6"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Content = ({ text, image }) => {
    return (
        <div className='feature-icon-container mx-4'>
            <div className='d-flex flex-column'>
                <img src={`/images/features/${image}`} className="feature-icon mx-auto" alt="..." />
                <span className="text-center fw-bold text-jobos-brown fs-25 mt-4">{text}</span>
            </div>
        </div>
    )
}

export default Features