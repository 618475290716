
const Reviews = ({ data }) => {
    return (
        <div className="pb-4">
            <div id="caruselReviews" className="carousel slide" data-bs-ride="carousel">
                <div className="row g-0">
                    <div className="col">
                        <div className='d-flex align-items-center justify-content-center h-100'>
                            <button className="border-0 p-0 bg-transparent" type="button" data-bs-target="#caruselReviews" data-bs-slide="prev">
                                <i className="fa-solid fa-chevron-left text-jobos-green fs-45"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-7">
                        <div className="carousel-inner">
                            {
                                data.map((review, index) => {
                                    return (
                                        <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                            <div className="reviews-text">
                                                <p className="fs-15 fw-normal lh-30 mb-5">{review.usercomment}</p>
                                            </div>
                                            <div className="fw-normal lh-30">
                                                <p className="fs-18 m-0">{review.username}</p>
                                                <p className="fs-14 m-0">{review.usercountry}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col">
                        <div className='d-flex align-items-center justify-content-center h-100'>
                            <button className="border-0 p-0 bg-transparent" type="button" data-bs-target="#caruselReviews" data-bs-slide="next">
                                <i className="fa-solid fa-chevron-right text-jobos-green fs-45"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reviews