import React, { useState, useEffect, useRef, useCallback } from 'react'
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectHouses, fetchHousesPrices } from "../../redux/slices/housesSlice";
import { fetchHousesDates } from '../../redux/slices/housesDatesSlice';
import { setHouseId, setDates, setTotalPrice, selectForm } from '../../redux/slices/formSlice';
import useDates from '../../hooks/useDates';
import usePrice from '../../hooks/usePrice';
import './css/DatePicker.css'
import './css/ReactCalendar.css';
import "./css/Form.css";

const DatePicker = ({ cabinSelectorIsActive = true, cabinId = 0, shadowIsActive = true }) => {
    const { t } = useTranslation();
    const { pick, intext, outtext, reset, button, locale } = t("datepicker.calendar")

    const { countDaysBetweenDates, isEarlierDate, isLaterDate, isWithinRanges, addMonths, addDays } = useDates();
    const { calculateTotalPrice } = usePrice();
    const dispatch = useDispatch();
    const houses = useSelector(selectHouses);
    const { totalPrice } = useSelector(selectForm);

    const now = new Date();
    const hours = now.getHours().toLocaleString("es-CR");
    const days = hours >= 17 ? 2 : 1;

    const [disabledDates, setDisabledDates] = useState([]);
    const [largeDisabledDates, setLargeDisabledDates] = useState([]);
    const [maxDate, setMaxDate] = useState(addMonths(new Date(), 2));
    const [minDate, setMinDate] = useState(addDays(new Date(), days));
    const [datesInfo, setDatesInfo] = useState(null);
    const [houseName, setHouseName] = useState(null);
    const [dataValid, setDataValid] = useState(false);
    let datesRef = useRef(null);
    let houseRef = useRef(null);
    let defaultPriceRef = useRef(0);

    const getDateRanges = useCallback((dates) => {
        const disabledDatesList = [];
        for (let index = 0; index < dates.length; index++) {
            let startDate = new Date(dates[index].startdate);
            let endDate = new Date(dates[index].enddate);
            endDate.setDate(endDate.getDate() - 1);
            let date = [startDate, endDate];
            disabledDatesList.push(date);
        }
        disabledDatesList.sort(
            (a, b) => a[0].getTime() - b[0].getTime()
        );
        return disabledDatesList;
    }, []);

    const getDatesBetween = useCallback((startDate, endDate) => {
        const dates = [];
        const currentDate = startDate;
        while (currentDate < endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    }, []);

    useEffect(() => {
        dispatch(setHouseId(null));
        dispatch(setDates(null));
        dispatch(setTotalPrice(0));
        if (!cabinSelectorIsActive) {
            houseRef.current = houses.find(house => house.id === cabinId).name;
            dispatch(fetchHousesPrices(cabinId))
            defaultPriceRef.current = houses.find(house => house.id === cabinId).price;
            dispatch(setHouseId(cabinId));

            dispatch(fetchHousesDates(cabinId)).then((data) => {
                const housesDates = data.payload;
                const disabledDatesList = getDateRanges(housesDates);
                setDisabledDates(disabledDatesList);

                const largeDisabledDatesList = getDateRanges(housesDates);

                const auxDatesList = []
                for (let index = 0; index < largeDisabledDatesList.length; index++) {
                    const datesBetween = getDatesBetween(largeDisabledDatesList[index][0], largeDisabledDatesList[index][1]);
                    auxDatesList.push(...datesBetween);
                }
                setLargeDisabledDates(auxDatesList);
            })
        }
    }, [cabinSelectorIsActive, cabinId, houses, dispatch, getDateRanges, getDatesBetween])

    function setDisabledDatesList(id) {
        dispatch(fetchHousesDates(id)).then((data) => {
            const housesDates = data.payload;
            const disabledDatesList = getDateRanges(housesDates);
            setDisabledDates(disabledDatesList);

            const largeDisabledDatesList = getDateRanges(housesDates);

            const auxDatesList = []
            for (let index = 0; index < largeDisabledDatesList.length; index++) {
                const datesBetween = getDatesBetween(largeDisabledDatesList[index][0], largeDisabledDatesList[index][1]);
                auxDatesList.push(...datesBetween);
            }
            setLargeDisabledDates(auxDatesList);
        })
    }

    function resetSelection() {
        setMaxDate(addMonths(new Date(), 2));
        const now = new Date();
        const hours = now.getHours().toLocaleString("es-CR");
        const days = hours >= 17 ? 2 : 1;
        setMinDate(addDays(new Date(), days));
        setDatesInfo(null);
        datesRef.current = null;
        dispatch(setDates(null));
        dispatch(setTotalPrice(0));
    }

    function tileDisabled({ date, view }) {
        // Disable tiles in month view only
        if (view === 'month') {
            // Check if a date React-Calendar wants to check is within any of the ranges
            return isWithinRanges(date, disabledDates);
        }
    }

    function restrictMaxDate(date) {
        for (let index = 0; index < largeDisabledDates.length; index++) {
            if (isLaterDate(largeDisabledDates[index], date)) {
                setMaxDate(largeDisabledDates[index]);
                return;
            }
        }
    }

    function restrictMinDate(date) {
        for (let index = largeDisabledDates.length - 1; index >= 0; index--) {
            if (isEarlierDate(largeDisabledDates[index], date)) {
                if (isLaterDate(largeDisabledDates[index], minDate)) {
                    setMinDate(largeDisabledDates[index]);
                }
                return;
            }
        }
    }

    function validateData() {
        if (datesRef.current == null || houseRef.current == null || countDaysBetweenDates(datesRef.current[0], datesRef.current[1]) < 2) {
            setDataValid(false);
        }
        else {
            setDataValid(true);
        }
    }

    function formatDate(date) {
        return date.toLocaleDateString("es-CR");
    }

    return (
        <div className={`date-picker ${shadowIsActive ? "date-picker-shadow" : ""} p-4 d-flex flex-column align-items-center`}>
            {
                cabinSelectorIsActive &&
                <div className='dropdown position-relative w-100'>
                    <button className="dp-dropdown dropdown-toggle d-flex align-items-center justify-content-between" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className='fw-medium fs-14'>{houseName ? houseName : pick}</span>
                        <i className="fa-solid fa-angle-down text-jobos-green"></i>
                    </button>
                    <ul className="dropdown-menu w-100" style={{ color: "#969696" }}>
                        {
                            houses.map((house, index) => {
                                return (
                                    <li key={index}>
                                        <button
                                            className="dropdown-item"
                                            onClick={() => {
                                                setHouseName(house.name);
                                                dispatch(setHouseId(house.id));
                                                dispatch(fetchHousesPrices(house.id));
                                                houseRef.current = house.name
                                                defaultPriceRef.current = house.price;
                                                setDisabledDatesList(house.id);
                                                resetSelection();
                                                validateData();
                                            }}
                                        >{house.name}</button>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            }
            <div className='d-flex text-start dp-input-container'>
                <div className='dp-input separator' >
                    <label>{intext}</label>
                    <input type='text' value={datesInfo ? `${formatDate(datesInfo[0])}` : "dd/mm/yyyy"} readOnly />
                </div>
                <div className='dp-input'>
                    <label>{outtext}</label>
                    <input type='text' value={datesInfo ? `${formatDate(datesInfo[1])}` : "dd/mm/yyyy"} readOnly />
                </div>
            </div>
            <div className="d-flex justify-content-between w-100">
                <p className='ps-3 text-jobos-green m-0 my-auto me-auto'>${totalPrice}</p>
                <p
                    className='dp-reset'
                    onClick={() => {
                        resetSelection();
                        validateData();
                    }}
                >
                    {reset}
                </p>
            </div>
            <Calendar
                locale={locale}
                selectRange={true}
                minDate={minDate}
                maxDate={maxDate}
                onClickDay={date => {
                    restrictMaxDate(date);
                    restrictMinDate(date);
                }}
                onChange={date => {
                    setDatesInfo(date);
                    datesRef.current = date;
                    dispatch(setDates([formatDate(date[0]), formatDate(date[1])]));
                    let totalPrice = calculateTotalPrice(new Date(date[0]), new Date(date[1]), defaultPriceRef.current);
                    dispatch(setTotalPrice(totalPrice));
                    validateData();
                }}
                tileDisabled={tileDisabled}
                value={datesInfo}
                nextLabel={<i className="fa-solid fa-chevron-right"></i>}
                prevLabel={<i className="fa-solid fa-chevron-left"></i>}
            />

            {
                dataValid ?
                    <button type="button" className='dp-btn mt-4' data-bs-toggle="modal" data-bs-target="#formModal">
                        {button}
                    </button> :
                    <button type="button" className='dp-btn mt-4' data-bs-toggle="modal" data-bs-target="#errorModal">
                        {button}
                    </button>
            }
        </div>
    )
}

export default DatePicker