import { configureStore } from '@reduxjs/toolkit'
import darkModeSlice from './slices/darkModeSlice'
import heroImagesSlice from './slices/herosImagesSlice'
import housesSlice from './slices/housesSlice'
import housesImagesSlice from './slices/housesImagesSlice'
import housesDatesSlice from './slices/housesDatesSlice'
import formSlice from './slices/formSlice'
import translationSlice from './slices/translationSlice'

export const store = configureStore({
    reducer: {
        darkMode: darkModeSlice,
        heroImages: heroImagesSlice,
        houses: housesSlice,
        housesImages: housesImagesSlice,
        housesDates: housesDatesSlice,
        form: formSlice,
        translation: translationSlice
    },
})