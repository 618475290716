
const Video = () => {
    return (
        <div className='py-5'>
            <div className='ratio ratio-16x9' >
                <video muted controls>
                    <source src="/video/video.mp4" type="video/mp4" />
                </video>
            </div>
        </div>
    )
}

export default Video