import { useEffect, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { fetchHouses, fetchActivities } from "./redux/slices/housesSlice";
import { fetchHeroImages } from "./redux/slices/herosImagesSlice";
import Layout from "./components/layout/Layout";
import Home from "./pages/Home";
import HousePitahaya from "./pages/HousePitahaya";
import AboutUs from "./pages/AboutUs";
import HouseMolenillo from "./pages/HouseMolenillo";
import Error from "./pages/Error";
import Checkout from "./pages/Checkout";


const router = createBrowserRouter([

  {
    path: "/",
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Home />
      }, {
        path: "/molenillo",
        element: <HouseMolenillo />
      }, {
        path: "/pitahaya",
        element: <HousePitahaya />
      }, {
        path: "/about-us",
        element: <AboutUs />
      }, {
        path: "/checkout",
        element: <Checkout />
      },

    ]
  }
]);

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHouses())
    dispatch(fetchHeroImages())
    dispatch(fetchActivities())
  }, [dispatch])

  return (
    <RouterProvider router={router} />
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...is loading">
      <App />
    </Suspense>
  );
}
