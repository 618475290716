import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectActivities } from '../../redux/slices/housesSlice';
import { selectDarkMode } from '../../redux/slices/darkModeSlice';
import { selectLanguage } from "../../redux/slices/translationSlice"
import useChunks from "../../hooks/useChunks";

const ActivitiesAround = () => {
    const { t } = useTranslation();
    const { groupByChunks } = useChunks();

    const { title, subtitle } = t('home.activities');
    const activities = useSelector(selectActivities);
    const language = useSelector(selectLanguage)

    const activitiesChunks = groupByChunks(activities, 3);

    return (
        <div className='py-5'>
            <div className='p-xl-3'>
                <div className='py-5 px-3 text-center'>
                    <p className='text-jobos-green fs-20'>{title}</p>
                    <p className='text-jobos-brown fs-45 fw-bold m-0'>{subtitle}</p>
                </div>
                {
                    activities.length > 0 &&
                    <>
                        <div id="carouselActivities" className="carousel slide d-none d-xxl-block" data-bs-ride="carousel">
                            <div className="d-flex justify-content-center justify-content-xxl-end pb-5 me-xxl-4">
                                <button className="circle-btn bg-jobos-green me-3" type="button" data-bs-target="#carouselActivities" data-bs-slide="prev">
                                    <i className="fa-solid fa-chevron-left text-white fs-6"></i>
                                </button>
                                <button className="circle-btn bg-jobos-green" type="button" data-bs-target="#carouselActivities" data-bs-slide="next">
                                    <i className="fa-solid fa-chevron-right text-white fs-6"></i>
                                </button>
                            </div>
                            <div className="carousel-inner pb-5">
                                {
                                    activitiesChunks.map((chunk, index) => {
                                        return (
                                            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                <div className='d-flex justify-content-around'>
                                                    {
                                                        chunk.map((activity, index) => {
                                                            const { name, nameesp, description, descriptionesp, url } = activity
                                                            return (
                                                                <Content key={index} name={language === 'en' ? name : nameesp} description={language === 'en' ? description : descriptionesp} url={url} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div id="carouselActivitiesMobile" className="carousel slide d-xxl-none" data-bs-ride="carousel">
                            <div className="d-flex justify-content-center justify-content-xxl-end pb-5 me-xxl-4">
                                <button className="circle-btn bg-jobos-green me-3" type="button" data-bs-target="#carouselActivitiesMobile" data-bs-slide="prev">
                                    <i className="fa-solid fa-chevron-left text-white fs-6"></i>
                                </button>
                                <button className="circle-btn bg-jobos-green" type="button" data-bs-target="#carouselActivitiesMobile" data-bs-slide="next">
                                    <i className="fa-solid fa-chevron-right text-white fs-6"></i>
                                </button>
                            </div>
                            <div className="carousel-inner pb-5">
                                {
                                    activitiesChunks.map((chunk, index) => {
                                        return (
                                            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                <div className='d-flex flex-column align-items-center'>
                                                    {
                                                        chunk.map((activity, index) => {
                                                            return (
                                                                <Content key={index} name={activity.name} description={activity.description} url={activity.url} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

const Content = ({ name, description, url }) => {
    const darkMode = useSelector(selectDarkMode);
    return (
        <div className='mb-5 mb-xxl-0'>
            <div className='d-flex flex-column'>
                <img src={`${url}`} className="activities-image mx-auto mb-3" alt="..." />
                <div className="activities-text px-4 px-xxl-0">
                    <p className={`fw-bold fs-25 ${darkMode ? "text-white" : ""}`}>{name}</p>
                    <p className={`fs-14 ${darkMode ? "text-white" : "text-jobos-gray"}`}>{description}</p>
                </div>
            </div>
        </div>
    )
}

export default ActivitiesAround