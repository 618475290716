import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setHouseReservation } from "../../api/api";

const initialState = {
    houseId: null,
    dates: null,
    totalPrice: 0,
    response: null,
    datesAreAvailable: null,
    status: "idle"
};

export const submitForm = createAsyncThunk(
    "form/submitForm",
    async (reservation) => {
        const response = await setHouseReservation(reservation);
        return response.data;
    }
);

export const formSlice = createSlice({
    name: "form",
    initialState,
    reducers: {
        setHouseId: (state, action) => {
            state.houseId = action.payload;
        },
        setDates: (state, action) => {
            state.dates = action.payload;
        },
        setDatesAreAvailable: (state, action) => {
            state.datesAreAvailable = action.payload;
        },
        setTotalPrice: (state, action) => {
            state.totalPrice = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitForm.pending, (state) => {
                state.status = "loading";
            })
            .addCase(submitForm.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.response = action.payload;
            })
            .addCase(submitForm.rejected, (state) => {
                state.status = "failed";
            });
    }
});

export const { setHouseId, setDates, setDatesAreAvailable, setTotalPrice } = formSlice.actions;

export const selectForm = (state) => state.form;

export default formSlice.reducer;