import { isWithinInterval } from "date-fns";
import { useSelector } from "react-redux"
import { selectPrices } from "../redux/slices/housesSlice"

const usePrice = () => {
    const datePrices = useSelector(selectPrices);

    function getDatesBetween(startDate, endDate) {
        const dates = [];
        const currentDate = startDate;
        while (currentDate < endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
            if (currentDate.getDate() === endDate.getDate()) {
                break;
            }
        }
        return dates;
    }

    function calculateTotalPrice(startDate, endDate, defaultPrice) {
        const fullDatesList = getDatesBetween(startDate, endDate);
        let totalPrice = 0;
        if (datePrices.length === 0) {
            for (let i = 0; i < fullDatesList.length; i++) {
                totalPrice += parseInt(defaultPrice)
            }
        } else {
            for (let i = 0; i < fullDatesList.length; i++) {
                let tempPrice = 0;
                for (let datePrice of datePrices) {
                    const isw = isWithinInterval(fullDatesList[i], { start: new Date(datePrice.startdate), end: new Date(datePrice.enddate) });
                    if (isw) {
                        tempPrice = datePrice.price;
                        break;
                    } else {
                        tempPrice = defaultPrice;
                    }
                }
                totalPrice += parseInt(tempPrice);
            }
        }
        return `${totalPrice}`;
    }

    return { calculateTotalPrice }
}

export default usePrice