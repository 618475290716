import { differenceInCalendarDays, isWithinInterval, areIntervalsOverlapping, differenceInDays } from 'date-fns';
import add from 'date-fns/add';

const useDates = () => {

    function countDaysBetweenDates(startDate, endDate) {
        return differenceInDays(endDate, startDate);
    }

    function isSameDay(a, b) {
        return differenceInCalendarDays(a, b) === 0;
    }

    function isEarlierDate(a, b) {
        return differenceInCalendarDays(a, b) < 0;
    }

    function isLaterDate(a, b) {
        return differenceInCalendarDays(a, b) > 0;
    }

    function isWithinRange(date, range) {
        return isWithinInterval(date, { start: range[0], end: range[1] });
    }

    function isWithinRanges(date, ranges) {
        return ranges.some(range => isWithinRange(date, range));
    }

    function addMonths(date, months) {
        return add(date, { months });
    }

    function addDays(date, days) {
        return add(date, { days });
    }

    function parseStringToDate(date) {
        const dateArray = date.split("/");
        const day = dateArray[0];
        const month = dateArray[1] - 1;
        const year = dateArray[2];
        return new Date(year, month, day);
    }

    function checkIfDatesAreOverlapping(startDate, endDate, housesDates) {
        const overlapping = housesDates.some(date => {
            return areIntervalsOverlapping(
                { start: startDate, end: endDate },
                { start: new Date(date.startdate), end: new Date(date.enddate) }
            );
        });
        return overlapping;
    }

    function checkIfStartDateIsWithinRanges(startDate, housesDates) {
        const withinRanges = housesDates.some(date => {
            if (isSameDay(startDate, new Date(date.enddate))) {
                return false;
            } else {
                return isWithinRanges(startDate, [[new Date(date.startdate), new Date(date.enddate)]]);
            }
        });
        return withinRanges;
    }

    function checkIfEndDateIsWithinRanges(endDate, housesDates) {
        const withinRanges = housesDates.some(date => {
            if (isSameDay(endDate, new Date(date.startdate))) {
                return false;
            } else {
                return isWithinRanges(endDate, [[new Date(date.startdate), new Date(date.enddate)]]);
            }
        });
        return withinRanges;
    }

    return {
        isSameDay,
        isEarlierDate,
        isLaterDate,
        isWithinRange,
        isWithinRanges,
        addMonths,
        addDays,
        parseStringToDate,
        checkIfDatesAreOverlapping,
        checkIfStartDateIsWithinRanges,
        checkIfEndDateIsWithinRanges,
        countDaysBetweenDates
    }
}

export default useDates