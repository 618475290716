export const aboutCarousel = [
    "c1.png",
    "c2.png",
    "c3.png",
    "c4.png",
    "c5.jpg",
    "c6.jpg",
    "c7.jpg",
    "c8.png",
]
export default aboutCarousel;