import axios from "axios";
const baseURL = "https://zm3x36i1inftpll-listica.adb.us-phoenix-1.oraclecloudapps.com/ords/jobos/services/";

export const getHeroImages = async () => {
    try {
        const data = await axios.get(`${baseURL}hero-images-list/`);
        return data;
    }
    catch (error) {
        console.log(error);
    }
}

export const getHouses = async () => {
    try {
        const data = await axios.get(`${baseURL}houses/`);
        return data;
    }
    catch (error) {
        console.log(error);
    }
}

export const getHousesReviews = async (id) => {
    try {
        const data = await axios.get(`${baseURL}houses-reviews-list/${id}`);
        return data;
    }
    catch (error) {
        console.log(error);
    }
}

export const getHousesAmenities = async (id) => {
    try {
        const data = await axios.get(`${baseURL}houses-amenities-list/${id}`);
        return data;
    }
    catch (error) {
        console.log(error);
    }
}

export const getHousesImages = async (id) => {
    try {
        const data = await axios.get(`${baseURL}houses-images-list/${id}`);
        return data;
    }
    catch (error) {
        console.log(error);
    }
}

export const getHousesDates = async (id) => {
    try {
        const data = await axios.get(`${baseURL}houses-dates/${id}`);
        return data;
    }
    catch (error) {
        console.log(error);
    }
}

export const getHousesPrices = async (id) => {
    try {
        const data = await axios.get(`${baseURL}houses-prices/${id}`);
        return data;
    }
    catch (error) {
        console.log(error);
    }
}

export const setHouseReservation = async (reservation) => {
    try {
        const data = await axios.post(`${baseURL}houses-reservation/`, reservation);
        return data;
    }
    catch (error) {
        console.log(error);
    }
}

export const getActivities = async () => {
    try {
        const data = await axios.get(`${baseURL}houses-activities-list/`);
        return data;
    }
    catch (error) {
        console.log(error);
    }
}